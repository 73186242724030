import { Injectable, inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

/**
 * Local storage keys used by the auth service.
 */
export enum AuthLocalStorageKeys {
  customRedirectURL = 'auth.custom_redirect_url',
}

/**
 * A service that provides authentication-related functionality.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private _customRedirectURL: string | null = null;

  public isAuthenticated() {
    return this.oidcSecurityService.isAuthenticated();
  }

  public checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  public login(customRedirectURL: string | null = null) {
    if (customRedirectURL) {
      this.setCustomAuthRedirectURL(customRedirectURL);
    }
    this.oidcSecurityService.authorize();
  }

  public async logout() {
    await lastValueFrom(this.oidcSecurityService.logoff());
    this.clearCustomAuthRedirectURL();
  }

  public getConfig() {
    return this.oidcSecurityService.getConfigurations();
  }

  public get customRedirectURL() {
    return this._customRedirectURL;
  }

  public set customRedirectURL(url: string | null) {
    this._customRedirectURL = url;
  }

  public clearCustomRedirectURL() {
    this._customRedirectURL = null;
  }

  public setCustomAuthRedirectURL(url: string) {
    localStorage.setItem(AuthLocalStorageKeys.customRedirectURL, url);
  }

  public getCustomAuthRedirectURL() {
    return localStorage.getItem(AuthLocalStorageKeys.customRedirectURL);
  }

  public clearCustomAuthRedirectURL() {
    localStorage.removeItem(AuthLocalStorageKeys.customRedirectURL);
  }
}
